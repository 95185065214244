import React from 'react';
import PropTypes from 'prop-types';
import { t } from '../../../../domain/services/configService';
import { ContainerInner } from '../../atoms/ContainerInner';
import { CardGridLeftRightWithAdLegacy } from '../CardGridLeftRightWithAdLegacy';

export const RelatedVideos = ({ videos, content }) => {
  const cardItems = videos.map(video => {
    const imageEntity = {
      mediaImage: {
        url: video.image_url,
        width: 1920,
        height: 1080
      }
    };
    const taxonomies = [{ name: video.tag, isPtt: true }];
    return {
      data: {
        type: 'video',
        url: video.url,
        title: video.title,
        imageEntity,
        videoDuration: video.duration,
        taxonomies
      }
    };
  });

  return (
    <ContainerInner>
      <CardGridLeftRightWithAdLegacy
        header={t('explore videos')}
        cardItems={cardItems}
        content={content}
      />
    </ContainerInner>
  );
};

RelatedVideos.propTypes = {
  videos: PropTypes.arrayOf(PropTypes.any).isRequired,
  content: PropTypes.objectOf(PropTypes.any).isRequired
};
